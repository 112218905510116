.outerContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 5%;
  color: #3c3e55;

  background-color: #30c1a7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url('../../assets/imgs/2ndSectionMask.png');
}

.content {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-around;
}

.textContainer {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  padding: 5rem 4rem;
}

.textListItem {
  margin-top: 1rem;
}

.imageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.hospitalImg {
  width: 400px;
}

@media only screen and (max-width: 992px) {
  .textContainer {
    max-width: 520px;
    padding: 2rem 1rem;
    margin: 20px;
  }

  .textListItem {
    margin-left: 20px;
  }

  .content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
  }

  .hospitalImg {
    margin-left: 50px;
    width: 320px;
  }
}
