.home__landing {
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 10% 2rem;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 3rem 0 1rem;
  width: 100%;
}

.home_landing_pg_arrow {
  height: 4.5rem;
  margin-top: 1.2rem;
  margin-left: 1.8rem;
}

.landing-bg {
  height: 72vh;
}

.home h5 {
  color: #112414;
  opacity: 0.7;
  font-size: 15px;
  font-weight: 400;
}
.home h1 {
  font-size: 48px;
  font-weight: 700;
}
.home a:link,
a:visited {
  text-decoration: none;
  /* color: black; */
}

/* .home__landing-left {
  display: flex;
  flex: 1;
} */

.home__landing-left h1 {
  font-size: 2.7rem;
  color: #112414;
}
.home__3rdSection-right h1 {
  font-size: 2.7rem;
  color: #112414;
}
.home__landing-left h5 {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.home__landing-left li {
  margin-top: 1rem;
  font-size: 1.5rem;
}
.home__banner {
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 3rem 12%;
  margin: 1em 0;
}

.home__banner-img1 {
  height: 19em;
  bottom: -20em;
  left: 10em;
  z-index: 10;
  position: absolute;
}

.home__banner-img2 {
  height: 8em;
  bottom: -12em;
  right: 9em;
  z-index: 10;
  position: absolute;
}

.home__banner-img3 {
  display: none;
}

.homeEnding__banner {
  justify-content: center;
  align-items: center;
  align-self: center;
  padding: 0 120px;
}

.video-placeholder-img {
  width: 100vw;
  object-fit: cover;
  z-index: -1;
  position: relative;
}

.home__landing-right-img {
  height: 80vh;
  object-fit: contain;
  position: relative;
  top: 2.4rem;
}

.home__2ndSection {
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0rem 5%;
  background-color: #30c1a7;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  background-image: url('../assets/imgs/2ndSectionMask.png');
}

.home__2ndSection h2 {
  font-weight: 400;
  font-size: 2.1rem;
  color: white;
}
.home__2ndSection h1 {
  font-weight: 700;
  font-size: 2.1rem;
  margin-bottom: 0.7rem;
  color: white;
}
.home__2ndSection img {
  object-fit: contain;
  height: 60vh;
}
.home__2ndSectionMask {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 7%;
  background-size: center;
  background-position: right;
  background-repeat: no-repeat;

  /* background-color: red; */
}

.home__2ndSection_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home__2ndSection_item h3 {
  color: white;
  font-weight: 400;
  font-size: 28px;
}

.home__2ndSection_item_type {
  height: 65px;
  width: 65px;
  padding: 15px;
  border-radius: 50%;
}

.home__2ndSection_item_type img {
  height: 100%;
  background-color: transparent;
}

.home__2ndSection__landing-left h2 {
  margin-bottom: 1rem;
}

.home__3rdSection {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 8%;
}

.home__3rdSection-left {
  display: flex;
  flex-direction: column;
  flex: 8;
  justify-content: center;
  padding: 0 12%;
}

.home__3rdSection-right {
  display: flex;
  flex: 6;
  flex-direction: column;
}

.home__3rdSection li {
  font-size: 28px;
  line-height: 42px;
  margin: 15px 0;
}

.home__3rdSection-category {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 14vw;
  width: 14vw;
  margin: 1em;
  padding: 7%;
  background: linear-gradient(
    310.86deg,
    rgba(232, 232, 232, 0) -52.32%,
    rgba(17, 36, 20, 0.07) 84.26%
  );
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 7px;
}

.home__3rdSection-category img {
  height: 100%;
}

.singleRobotText {
  font-size: 1rem !important;
}

.home__3rdSectionCard {
  width: 15rem;
  height: 80px;
  background: linear-gradient(
    310.86deg,
    rgba(232, 232, 232, 0) -52.32%,
    rgba(17, 36, 20, 0.07) 84.26%
  );
  border-radius: 8px;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0.7rem;
  margin-left: 0;
  /* align-items: center; */
}

.home__3rdSectionCard h5 {
  margin: 0px;
}

.home__3rdSectionCard__View {
  display: flex;
  flex-wrap: wrap;
}

.home__deliverySection {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10%;
  color: white;
}

.home__deliverySection img:first-child {
  max-height: 100vh;
  max-width: 80vw;
  margin: 0;
  top: 1rem;
}

.home__deliverySection img:nth-child(2) {
  max-width: 40vw;
  max-height: 38vh;
  margin: 0;
  top: -3rem;
}

.home__4thSection {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 4%;
  background-color: #7a35c1;
  color: white;
}

.home__4thSection img {
  top: 0;
  height: 70vh;
}

.home__4thSection_right {
  width: 40vw;
}

.home__4thSection_right h1 {
  font-size: 35px;
  margin-bottom: 3rem;
}

.home__4thSection .playstore-img {
  height: 12vh;
  cursor: pointer;
}

.home__4thSection .appstore-img {
  height: 12.6vh;
  padding: 2vh;
  cursor: pointer;
}

.home__4thSection .store-buttons {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.home__4thSection_item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.home__4thSection_item_divider {
  background-color: #bae671;
  height: 24px;
  width: 4px;
  margin: -2px 0 -2px 2.66rem;
}

.home__4thSection_item_type {
  background-color: #bae671;
  height: 5.5rem;
  width: 5.5rem;
  padding: 1.6rem;
  border-radius: 50%;
  margin-right: 1.6rem;
}

.home__4thSection_item h2 {
  font-weight: 500;
  font-size: 24px;
}

.home__4thSection_item h4 {
  font-weight: 100;
  font-size: 24px;
  font-style: italic;
}

.home__4thSection_item_type img {
  height: 100%;
}

.video-section {
  padding: 0 10%;
}
.local-video {
  /* padding: 0 60px; */
  width: 100vw;
  /* aspect-ratio: initial; */
  object-fit: cover;
  /* margin: 0; */
  /* background-color: red; */
  /* margin-bottom: 2%; */
}
.local-video-container {
  overflow: hidden;
}
.video-frame {
  width: 100%;
  height: 80vh;
  overflow: hidden;
  border-radius: 1.2em;

  @media (max-device-width: 768px) {
    height: 400px;
  }
}

.video-responsive {
  overflow: hidden;
  display: flex;
  width: 100%;
}

.video-responsive iframe {
  top: 0;
  left: 0;
}

.landing-bg1 {
  width: 50vw;
  margin: 0 auto;
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1200px) {
  .home__landing-left {
    padding: 1rem 0;
  }
  .landing-bg {
    height: 50vh;
  }
  .home__landing-left h1 {
    font-size: 2.4rem;
  }
  .home__3rdSection-right h1 {
    font-size: 2.4rem;
  }
  .home__landing-right-img {
    width: 50vw !important;
    object-fit: contain;
    position: relative;
    top: 3rem;
  }
  .home__2ndSection__landing-left {
    flex: 1;
  }
  .home__2ndSection {
    flex-direction: column;
    align-items: flex-start;
  }
  .home__deliverySection {
    padding: 0;
    justify-content: center;
  }
  .home__deliverySection img:first-child {
    margin: 0 -2vw;
  }
}

@media only screen and (max-width: 992px) {
  .home__banner-img1 {
    display: none;
  }

  .home__banner-img2 {
    display: none;
  }

  .home__banner-img3 {
    display: block;
    width: 50vw;
  }

  .home__deliverySection {
    flex-direction: column-reverse;
    padding: 10vh 0;
    height: auto;
  }
  .home__4thSection_right h1 {
    font-size: 26px;
  }
  .home__4thSection_item h2 {
    font-size: 20px;
  }
  .home__4thSection_item h4 {
    font-size: 20px;
  }
  .home__landing-left h5 {
    font-size: 1rem;
  }
  .home__landing-left li {
    font-size: 1rem;
  }
  .landing-bg {
    height: 40vw;
  }
  .local-video-container {
    margin-top: 0rem;
  }
  .home__landing-left h1 {
    font-size: 2.1rem;
  }

  .home__3rdSection-right h1 {
    font-size: 2.1rem;
  }

  .home__4thSection {
    padding: 3rem 1rem;
    flex-direction: column;
  }
  .home__4thSection_right {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 90vw;
  }

  .home__landing-right-img {
    width: 36vw !important;
    object-fit: contain;
    position: relative;
    top: -1rem;
  }
  .home__2ndSection {
    padding: 0 3rem;
  }
  .home__3rdSection {
    flex-direction: column;
    padding: 2rem 5%;
  }
  .home__3rdSection-left {
    padding: 5% 20% 5% 25%;
  }
  .home__3rdSection-right {
    margin: 2rem 0;
  }
  .home__3rdSection-category {
    padding: 1vw;
  }
}

.home__landing-right-img1 {
  width: 45vw !important;
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .home_landing_pg_arrow {
    display: none;
  }
  .home__landing-right-img1 {
    top: -3.5rem;
  }
  .home__2ndSection {
    height: 110vh;
    width: 100%;
  }
  .home__3rdSectionCard__View {
    margin-top: 0.5rem;
  }
  .home__3rdSectionCard {
    width: 100%;
    margin: 0.5em 0;
  }
  .home__2ndSection {
    padding: 0 5%;
  }
  .home__landing-right-img1 {
    margin-top: 2rem;
    position: relative;
  }
  .landing-bg {
    display: none;
  }
  .home__landing {
    flex-direction: column;
  }
  .home__landing-left h1 {
    font-size: 1.5rem;
  }
  .home__3rdSection-right h1 {
    font-size: 1.5rem;
  }
  .home__2ndSection__landing-left h2 {
    font-size: 1.5rem;
  }
  .home__2ndSection__landing-left h5 {
    font-size: 1rem;
  }
  .home__2ndSection_item h3 {
    font-size: 22px;
  }
  .home__banner {
    padding: 0 5%;
  }
  .home__landing-right-img {
    display: none !important;
  }
  .home__2ndSectionMask {
    margin-top: 4.5rem;
    margin-right: 0px;
    padding: 0;
  }
  .home__landing-left {
    width: 100%;
  }
  .home__landing {
    padding: 0rem 5% 0rem;
  }
  .video-section {
    padding: 0 4%;
  }
  .home__4thSection .store-buttons {
    flex-direction: column;
  }
  .home__3rdSection-category {
    height: 27vw;
  }
}

@media only screen and (max-width: 576px) {
  .home__landing-left {
    width: 100%;
  }
  .landing-bg1 {
    width: 80vw;
    margin: 0 auto;
  }
  .home__landing-left {
    padding: 5% 0;
  }
  .home__landing-right-img1 {
    width: 70vw !important;
    position: relative;
    bottom: 1rem;
  }
  .home__2ndSection {
    height: 100vh;
    width: 100%;
  }
  .home__3rdSection-left {
    margin-left: 5%;
  }
}

@media only screen and (min-width: 768px) {
  .landing-bg1 {
    display: none;
  }
  .home__landing-right-img1 {
    display: none !important;
  }
}
