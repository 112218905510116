.footer {
  width: 100%;
  display: flex;
  /* align-items: center; */
  /* justify-content: space-between; */
  padding: 15rem 5% 3rem;
  flex-direction: column;
  justify-content: space-around;
  /* background: url("../assets/imgs/2ndSectionMask.png"); */
  background-color: #30c1a7;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  bottom: 0;
  position: relative;
  z-index: 1;
}

.footer h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;

  color: #112414;
}
.footer h6 {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #112414;
}
.footer__links {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  /* height: 100%; */
}

.footerLinkCont {
  margin-right: 2rem;
  margin-bottom: 2rem;
}

.aboutUs__links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: red; */
  width: 100%;
}

.aboutUs__links a:link,
a:visited {
  text-decoration: none;
  color: black;
  border-color: #000;
}

.aboutUs__links a:hover,
a:active {
  text-decoration: underline;
  text-decoration-color: #000;
}

.aboutUs__item {
  margin: 1.2em;
  margin-left: 0;
  text-decoration: none;
}

@media (max-width: 768px) {
  .footer__links {
    flex-direction: column;
    align-items: flex-start;
  }
}

.footer-link {
  color: #000 !important;
}

.footer-logo {
  width: 10rem;
  margin-bottom: 2rem;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .banner h1 {
    font-size: 1.5rem;
  }
  .banner p {
    font-size: 0.9rem;
  }
}
