.App {
}

:root {
  --background: #2b2b2b;
  --background-white: #f7f8fb;
  --secondary: #8964e0;
  --primary: #30c1a7;
  --grey: #5e5a59;
  --text-grey: #b1adac;
  --gradient: linear-gradient(
    130.99deg,
    var(--primary) 36.65%,
    var(--secondary) 98.98%
  );
}
