.banner {
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #2f2e3a;
  padding: 10% 0;
  padding-left: 20%;
  height: 10em;
  color: white;
  width: 100%;
  z-index: 999;
  align-self: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  position: relative;
  z-index: 2;
}

.banner h1 {
  font-size: 36px;
  color: white;
  text-align: center;
}

.banner p {
  font-size: 24px;
  font-weight: 200;
  letter-spacing: 1.2px;
  line-height: 28px;
  margin: 10px 0;
}

.ending__banner_container {
  height: 70vh;
  margin-top: 20vh;
  padding: 3rem 0;
  background: url('../assets/imgs/curve.svg');
  background-size: cover;
}

.ending__banner_top {
  display: flex;
  flex-direction: row;
  top: -11rem;
  position: relative;
  justify-content: flex-start;
  padding: 0 10%;
  align-items: center;
}

.ending__banner_top_image {
  height: 22vw;
  /* position: relative; */
  /* left: 16vw; */
}

.ending__banner_top_image_right {
  width: 32vw;
}

.ending__banner {
  background: url('../assets/imgs/endingBanner.png');
  background-color: #2f2e3a;
  background-repeat: no-repeat;
  background-size: cover;
  /* position: absolute; */
  padding: 5% 3%;
  color: white;
  width: 70%;
  margin: 0 auto;
  z-index: 999;
  align-self: center;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
}

.endingBannerContactUsBtn {
  padding: 0.6rem 0.7rem;
  border: 1px solid #fff;
  border-radius: 0.4rem;
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  cursor: pointer;
  margin-top: 1.8rem;
  width: fit-content;
}

.ending__bannerAvatar {
  position: absolute;
  right: 1px;
  bottom: -5rem;
  object-fit: contain;
  width: 22rem;
}

.ending_banner_title {
  color: #fff;
  font-size: 2.4rem;
  font-weight: 700;
  margin-top: 0.9rem;
}

@media only screen and (max-width: 1200px) {
  .ending__bannerAvatar {
    width: 21rem;
    bottom: -8rem;
  }
}

@media only screen and (max-width: 992px) {
  .ending__bannerAvatar {
    width: 18rem;
    bottom: -7rem;
  }
  .ending_banner_title {
    font-size: 1.8rem;
    margin-bottom: 0.7rem;
  }
  .endingBannerContactUsBtn {
    width: 95%;
    text-align: center;
    margin-top: 1.8rem;
  }
  .ending__banner {
    width: 90%;
    padding-right: 0;
  }
  .banner {
    padding: 7%;
    height: auto;
  }

  .banner h1 {
  }

  .banner p {
    line-height: 24px;
  }

  .ending__banner_top {
    padding: 0 3%;
    justify-content: space-around;
  }

  .ending__banner_top_image {
    height: 36vw;
  }

  .ending__banner_top_image_right {
    width: 46vw;
  }
}

@media only screen and (max-width: 768px) {
  .ending__bannerAvatar {
    width: 40vw;
    bottom: -7rem;
    left: 30%;
  }
  .ending__banner {
    width: 90%;
    padding-bottom: 15rem;
  }
  .ending_banner_title {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 576px) {
  .ending__bannerAvatar {
    width: 60vw;
    bottom: -7rem;
    left: 24%;
  }
  .ending__banner {
    width: 90%;
    padding-bottom: 14rem;
  }
}

@media only screen and (max-width: 480px) {
  .ending__banner {
    width: 90%;
    padding-bottom: 12rem;
  }
}
