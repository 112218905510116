.outerContainer {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0rem 5%;
  color: #3c3e55;

  background-color: #ffffff;
}

.content {
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 80%;
  justify-content: space-between;
}

.LeftTextContainer {
  max-width: 600px;
  justify-self: flex-start;
  padding: 5rem 4rem;
}

.RightTextContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.rightCard {
  background-color: #30c1a7;
  width: 183px;
  height: 183px;
  padding: 1rem;
  border-radius: 14px;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.cardText {
  text-align: center;
  margin-top: 8px;
  color: #ffffff;
}

.eclipse {
  height: 80px;
  width: 80px;
  display: flex;
  justify-content: center;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.2);
}

.imageContainer {
  display: flex;
  justify-self: flex-end;
  justify-content: center;
  align-items: center;
}

.dividerPill {
  width: 70px;
  height: 9px;
  border-radius: 9px;
  background-color: black;
  margin: 1rem 0px;
}

.docReview {
  font-weight: 600;
  margin: 8px 0px;
  font-style: italic;
  opacity: 0.8;
  max-width: 400px;
  font-size: 22px;
}

.docName {
  font-size: small;
  font-style: italic;
  font-weight: 300;
}

.textListItem {
  margin-top: 1rem;
}

.img {
  position: absolute;
  right: 7rem;
  bottom: 0px;
  width: 811px;
}

@media only screen and (max-width: 1270px) {
  .img {
    display: none;
  }
}

@media only screen and (max-width: 990px) {
  .content {
    flex-direction: column;
  }

  .RightTextContainer {
    flex-direction: row;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 630px) {
  .RightTextContainer {
    display: grid;
    gap: 2px;
    grid-template-columns: 1fr 1fr;

    justify-content: center;
    margin: 2rem 0px;
  }

  .LeftTextContainer {
    padding: 5rem 2rem;
  }

  .rightCard {
    padding: 4px;
  }
}
