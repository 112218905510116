.header-img {
  width: 10rem;
}

.header__container {
  width: auto;
  background-color: #30C1A7;
  border-radius: 16px;
  padding: 18px 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header {
  position: absolute;
  margin: 1rem 3%;
}

.header__nav {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.header__nav .header__nav-link {
  text-decoration: none;
  color: white;
  font-size: 16px;
  padding: 6px 15px 10px 15px;
  margin-top: 0;
}

.header__nav-link:hover {
  background: #174331;
  border-radius: 80px;
}

.active {
  background: #174331;
  border-radius: 80px;
}

.header__menubtn {
  width: 42px;
  height: 42px;
  background: #174331;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  display: none;
}

.header__menubtn span {
  width: 18px;
  height: 2px;
  margin: 3px;
  background: white;
  border-radius: 50px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

.header__navMob {
  display: none;
}

@media (max-width: 460px) {
  .header__menubtn {
    display: flex;
  }

  .header__nav {
    display: none;
  }

  .header__navOpen {
    display: flex;
  }

  .header__navMob {
    margin-top: -1px;
    border: none;
    background-color: #112414;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    padding-bottom: 10px;
  }
}

@media only screen and (max-width: 1600px) {
}

@media only screen and (max-width: 1200px) {
  .header__container {
    padding: 18px 1.4rem;
  }
  .header-img {
    width: 8rem;
  }
}

@media only screen and (max-width: 992px) {
}

@media only screen and (max-width: 768px) {
}

@media only screen and (max-width: 576px) {
  .header__container {
    padding: 17px 1.4rem;
  }
  .header {
    position: relative;
    margin: 0.5rem;
  }
}

@media only screen and (max-width: 480px) {
}
